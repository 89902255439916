<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        When
        <number-value :value="volBase" unit="\text{mL}" />
        of a solution of strong base is mixed with
        <number-value :value="volAcid" unit="\text{mL}" />
        of a solution of acetic acid in a coffee-cup calorimeter, the neutralization reaction causes
        the temperature to increase from
        <number-value :value="roomTemp" unit="^\circ\text{C}" />
        to
        <latex-number :number="finalTemp.toFixed(2)" unit="^\circ\text{C.}" />
      </p>

      <p class="mb-n3">a) Is this reaction endothermic or exothermic?</p>

      <v-radio-group v-model="inputs.thermicity" :disabled="!allowEditing" class="ml-6 mb-0">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block mb-1" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-n3">b) Is the heat of the reaction positive or negative?</p>

      <v-radio-group v-model="inputs.signHeat" :disabled="!allowEditing" class="ml-6 mb-0">
        <div v-for="option in options2" :key="option.value">
          <v-radio class="d-inline-block mb-1" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-2">
        c) Assuming that the liquids have densities of 1.00 g/mL, what is the total mass of the
        solution?
      </p>

      <calculation-input
        v-model="inputs.massSoln"
        class="mb-5"
        prepend-text="$\text{Mass}:$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        d) Determine the heat,
        <stemble-latex content="$\text{q},$" />
        in units of J for the neutralization reaction if the heat capacity of the solution is
        <stemble-latex
          content="$1.00\,\text{cal}\,\text{g}^{-1}\,^\circ\text{C}^{-1} (1\,\text{cal}=4.184\,\text{J}).$"
        />
      </p>

      <calculation-input
        v-model="inputs.heat"
        class="mb-0"
        prepend-text="$\text{q:}$"
        append-text="$\text{J}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalReactionInput from '@/tasks/components/inputs/ChemicalReactionInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'Question457',
  components: {
    LatexNumber,
    NumberValue,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        thermicity: null,
        signHeat: null,
        massSoln: null,
        heat: null,
      },
      options1: [
        {expression: '$\\text{Exothermic}$', value: 'exothermic'},
        {expression: '$\\text{Endothermic}$', value: 'endothermic'},
        {expression: '$\\text{More information required}$', value: 'moreInfoNeeded'},
      ],
      options2: [
        {expression: '$\\text{Positive}$', value: 'positive'},
        {expression: '$\\text{Negative}$', value: 'negative'},
        {expression: '$\\text{More information required}$', value: 'moreInfoNeeded'},
      ],
    };
  },
  computed: {
    volBase() {
      return this.taskState.getValueBySymbol('volBase').content;
    },
    volAcid() {
      return this.taskState.getValueBySymbol('volAcid').content;
    },
    tempChange() {
      return this.taskState.getValueBySymbol('tempChange').content;
    },
    roomTemp() {
      return this.taskState.getValueBySymbol('roomTemp').content;
    },
    finalTemp() {
      return this.tempChange.toFloat() + this.roomTemp.toFloat();
    },
  },
};
</script>
